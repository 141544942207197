import React, { Component } from 'react';


class NoMatch extends Component {

    render() {
        return (
           
                <h1>No Match</h1>
            
        )
    }
}

export default NoMatch;